import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $ from "jquery";
const isEmpty = require('is-empty');

class Options extends Component {
	render() {
	    return(
	    	<div className="modal fade" id="options-message-modal" tabIndex="-1" role="dialog" style={{zIndex:2001}}>
			  <div className="modal-dialog custom modal-dialog-centered m-0 h-100" style={{alignItems: 'flex-end'}} role="document">
			    <div className="modal-content text-secondary" style={{border:'0'}}>
			      <div className="modal-header text-center justify-content-end py-2">
			      	<span data-dismiss="modal" aria-label="Close">
			         	Cerrar
			        </span>
			      </div>
			      <div className="modal-body px-3 py-0">
			      	{(()=>{
			      		if (typeof(this.props.messageIdx)=='number' && !isEmpty(this.props.massages[this.props.messageIdx].file_id)) {
			      			return <span className="d-block my-2">
					      				<a className="text-secondary text-decoration-none" href="#!" target="_blank"
					      					onClick={event=>{
					      						event.preventDefault();
					      						window.open(process.env.REACT_APP_API_URL+"/api/message/"+this.props.massages[this.props.messageIdx].id+"/file/down",'_blank');
					      					}}
					      				>
						      				Descargar
						      			</a>
					      			</span>
			      		}
			      	})()}
			      	<span className="d-block my-3" data-toggle="collapse" data-target="#collapseOptonMessage">Compartir</span>
			      	<div className="collapse" id="collapseOptonMessage">
					  <div className="card card-body">
					  	<p 
					  		onClick={()=>{
										window.FB.ui({
											app_id: '1147536148763952',
											method: 'share',
											href: window.location.host==="app.cumpledigital.com"?"cumpledigital.com":"ladiversiva.com",
											quote: 'Celebro hoy a '+this.props.room.name+'. Quiero compartir con vosotros el mensaje.\n'+this.props.massages[this.props.messageIdx].text,
											redirect_uri: window.location.host
										}, function(response){});
							}}
					  	>
					  		Facebook
					  	</p>
					  	<p
					  		className="mb-0"
					  		onClick={()=>{
										window.open(
											'https://twitter.com/intent/tweet?text='+window.encodeURI('Celebro hoy a '+this.props.room.name+'. Quiero compartir con vosotros el mensaje.\n'+this.props.massages[this.props.messageIdx].text),
											'top'
										)
							}}
					  	>
					  		Twitter
					  	</p>
					  </div>
					</div>
					{(()=>{
			      		if (
			      				!isEmpty(this.props.user) &&
			      				typeof(this.props.messageIdx)=='number' &&
			      				this.props.user.id === this.props.massages[this.props.messageIdx].user_id
			      			){
			      			return <span 
				      			className="d-block my-3" 
				      			onClick={()=>{
				      				$('#options-message-modal').modal('hide');
				      				this.props.messageToEdit(this.props.massages[this.props.messageIdx].id);
				      			}}
				      		>Editar</span>;
			      		}
			      	})()}
					{(()=>{
			      		if (
			      				(
				      				!isEmpty(this.props.user) && 
				      				this.props.user.web_user.funerals_to_manage.indexOf(this.props.room.id)>=0
				      			) 
				      			|| 
				      			(
				      				!isEmpty(this.props.user) && 
				      				typeof(this.props.messageIdx)=='number' && 
				      				this.props.user.id === this.props.massages[this.props.messageIdx].user_id
				      			)
			      			) {
			      			return <span 
			      						className="d-block my-3"
			      						onClick={()=>{
						      				$('#options-message-modal').modal('hide');
						      				this.props.messageToDelete(this.props.massages[this.props.messageIdx].id);
						      			}}
			      					>Borrar</span>;
			      		}
			      	})()}
			      	{(()=>{
			      		if (
				      			!isEmpty(this.props.user) && 
				      			this.props.user.web_user.funerals_to_manage.indexOf(this.props.room.id)>=0 &&
				      			typeof(this.props.messageIdx)=='number' && 
				      			this.props.user.id !== this.props.massages[this.props.messageIdx].user_id
			      			){
			      			return [<hr key="hr" className="m-0"/>,
			      					<span key="span" className="d-block my-3">Eliminar Invitado</span>];
			      		}
			      	})()}
			      	{(()=>{
			      		if ( 
			      				typeof(this.props.messageIdx)=='number' && 
			      				this.props.massages[this.props.messageIdx].status !== 'approved'
			      			){
			      			return [<hr key="hr" className="m-0"/>,
			      					<span key="collapse" className="d-block my-3" data-toggle="collapse" data-target="#collapseOptonMessageInfo">Info</span>,
							      	<div className="collapse" id="collapseOptonMessageInfo">
									  <div className="card card-body mb-3">
									  	<p className="m-0">Este mensaje esta bajo revisión.</p>
									  </div>
									</div>
								];
			      		}
			      	})()}
			      </div>
			    </div>
			  </div>
			</div>
	    );
	}
}

Options.propTypes = {
  app_settings: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  app_settings: state.app_settings,
  user: state.user,
  room: state.room,
});
export default connect(
  mapStateToProps,
  {}
)(Options);