import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { searchCode } from "../../actions/Room.action";
import { loginByCode } from "../../actions/User.action";
import { flushError } from "../../actions/Error.action";
// import Logo from '../../images/eternify_logo.png';
import ShowErrors from '../errors/ShowErrors.component';

const isEmpty = require('is-empty');

class CodeSearch extends Component {
	constructor() {
		super();
		this.imageOrTitle = this.imageOrTitle.bind(this);
		this.state = {
			code: "",
			logo_error:false
		};
	}
	onChange = e => {
		this.props.flushError();
		this.setState({ [e.target.id]: e.target.value });
	};
	onSubmit = e => {
		e.preventDefault();
		var form = document.getElementsByClassName('needs-validation')[1];
		form.classList.add('was-validated');
		if (form.checkValidity()) {
			const obj = {
		        code: this.state.code
		    };
		    this.props.searchCode(obj, this.props.history);
		}
	};
	imageOrTitle(){
	    if (isEmpty(this.props.app_settings.logo_id) || this.state.logo_error) {
	      return this.props.app_settings.title;
	    }else{
	      return <img src={process.env.REACT_APP_API_URL+'/api/business/'+this.props.app_settings.id+'/image/'} className="img-fluid" alt="" onError={()=>{this.setState({logo_error:true})}}/>;
	    }
	  }
	componentDidMount(){
		// console.log(this.props.match.params);
		if (!isEmpty(this.props.room)) {
			this.props.history.push('/room/phone');
		}
		if (!isEmpty(this.props.match.params.code) && isEmpty(this.props.match.params.login_info)) {
			const obj = {
		        code: this.props.match.params.code
		    };
		    this.props.searchCode(obj, this.props.history);
		}else if(!isEmpty(this.props.match.params.code) && !isEmpty(this.props.match.params.login_info)){
			console.log("here");
			const obj = {
		        code: this.props.match.params.code,
		        password: this.props.match.params.login_info
		    };
		    this.props.loginByCode(obj, this.props.history);
		}
	}
	render() {
		return (
			<div className="container">
				<div className="row d-flex align-items-center justify-content-center" style={{minHeight:'100vh'}}>
						<div className="col-sm-12 col-md-6 px-5">
							<div className="row pb-5">
								<div className="col text-center">
									{this.imageOrTitle()}
								</div>
							</div>
							<div className="row">
							</div>
							<form noValidate className="needs-validation" onSubmit={this.onSubmit}>
								<div className="form-group mb-4">
									<label htmlFor="code" className="font-weight-bold" style={{textAlign:'center',fontSize:"1rem"}}>Por favor, introduzca el código que aparece en la parte superior de la pantalla de la sala</label>
									<input
										onChange={this.onChange}
										value={this.state.code}
										id="code"
										type="code"
										className="form-control"
										required
									/>
									<ShowErrors name="code" frontendError="Se requiere el código"/>
								</div>
								<div className="form-group text-center">
									<button type="submit" className="btn btn-lg bg-first-color text-white btn-et">
										Aceptar
									</button>
								</div>
							</form>
						</div>
				</div>
			</div>
		);
	}
}
CodeSearch.propTypes = {
	searchCode: PropTypes.func.isRequired,
	loginByCode: PropTypes.func.isRequired,
	flushError: PropTypes.func.isRequired,
	// auth: PropTypes.object.isRequired,
	// errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	room: state.room,
	app_settings: state.app_settings,
	errors: state.errors
});
export default connect(
	mapStateToProps,
	{ searchCode,loginByCode,flushError }
)(withRouter(CodeSearch));