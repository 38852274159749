import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateRoom } from "../../actions/Room.action";
import Icon from "../others/Icon.component";
import ProgressBar from './ProgressBar.component';

const isEmpty = require('is-empty');

class Navbar extends Component {
	constructor() {
    super();
    this.updateRoom = this.updateRoom.bind(this);
    this.imageOrTitle = this.imageOrTitle.bind(this);
    this.state = {
      title:'Eternify',
      logo_error:false,
      percentage:0
    }
  }
	updateRoom(){
    const obj = {code:this.props.room.id,reload:true};
    this.props.updateRoom(obj, this.props.history);
    // setTimeout(()=>{
    //   window.location.reload();

    // },2000)
  }
  imageOrTitle(){
    if (isEmpty(this.props.app_settings.logo_id) || this.state.logo_error) {
      return <div className="brand-title">{this.props.app_settings.title}</div>;
    }else{
      return <img style={{maxHeight:'2rem'}} src={process.env.REACT_APP_API_URL+'/api/business/'+this.props.app_settings.id+'/image/'} className="img-fluid" alt="" onError={()=>{this.setState({logo_error:true})}}/>;
    }
  }
  componentDidMount(){
    if (!isEmpty(this.props.app_settings)) {
      this.setState({
        title:this.props.app_settings.title,
        percentage:this.props.app_vars.edit_load_percentage
      });
    }
    this.forceUpdate();
    this.setState({
        percentage:this.props.app_vars.edit_load_percentage
    });
  }
	render() {
				return (
						<div className="custom-navbar">
              <div className="row">
                <div className="col">
                </div>
                <div className="col text-center">
                  {this.imageOrTitle()}
                </div>
                <div className="col">
                  <Icon class="icon icon-reload" onClick={this.updateRoom}/>
                </div>
              </div>
              <div className="row">
                  <ProgressBar percentage={this.props.app_vars.edit_load_percentage} style={{width:'100%',position:'absolute',top:'100%',left:'0'}}/>
              </div>
		        </div>
				);
	}
}
Navbar.propTypes = {
  updateRoom: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  app_settings: PropTypes.object.isRequired,
  app_vars: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  user: state.user,
  room: state.room,
  app_settings: state.app_settings,
  app_vars: state.app_vars,
});
export default connect(
  mapStateToProps,
  { updateRoom }
)(Navbar);
